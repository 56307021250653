/* used in a shop directory to set up the 3 col product list per category etc */

import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'

class ShopifyPage extends Component {

  constructor(props) {
    super(props);
  }


  render() {

    const { handle } = this.props;

    return (
        <StaticQuery
          query={graphql`
          query ShopifyPageQuery {
            pages:
            allShopifyPage {
              edges {
                node {
                  id
                  handle
                  title
                  body
                  bodySummary
                }
              }
            }
          }
          `}
          render={({ pages }) => (
            <>
              {
                pages.edges.filter(page => page.node.handle === handle).map(page => {
                   return(
                     <span key={page.node.id} dangerouslySetInnerHTML={{ __html: `${page.node.body}` }} />
                   )
                })
              }
            </>
          )}
        />
    );
  }
  }

// .filter(({ node: product }) => product.availableForSale === true)

export default ShopifyPage;
