import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import ContainerText from '../../components/container_text'
import Config from '../../config'
import SEO from '../../components/seo'
import background from '../../images/backgrounds/stockists.jpg'
import ShopifyPage from '../../components/shopify_page';

const Title = styled.h3`
  padding-bottom: 2rem;
`
const Location = styled.p`
  margin-bottom: 40px;
  padding-bottom: 0px !important;
  color: ${props => props.theme.brown4};
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  .stockist {
    font-size: 13px;
    line-height: 20px;
    @media (max-width: 768px) {
      font-size: 15px;
      line-height: 21px;
      font-weight: bold;
    }
  }
  a, i {
    text-decoration: none;
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
    color: ${props => props.theme.brown4};
    transition: all 0.25s ease-in-out;
    font-family: sgi;
    @media (max-width: 768px) {
      font-size: 15px;
      line-height: 21px;
      font-weight: 300;
    }
  }
  a:hover {
    color: ${props => props.theme.brown4};
  }
`

const bgStyle = { backgroundImage: `url(${background})`, backgroundSize: 'cover', 'backgroundRepeat': 'no-repeat' };

const StockistContainer = styled.div`
  p {
    padding-bottom: 0.5rem;
  }
`

const IndexPage = () => (
  <div style={bgStyle}>
    <SEO title="Stockists" keywords={[`Stockists`, `Theodora`, `Warre`]} />
    <ContainerText top longpage>
      <Container>
        <Row><Col xs="12"><Title>Stockists</Title></Col></Row>
        <Row>
          <Col xl="6" lg="6" md="12" sm="12">
            <StockistContainer>
              <ShopifyPage handle="stockists-online" />
            </StockistContainer>
          </Col>
          <Col xl="6" lg="6" md="12" sm="12">
            <StockistContainer>
              <ShopifyPage handle="stockists-high-street" />
            </StockistContainer>
          </Col>
        </Row>
      </Container>
    </ContainerText>
  </div>
)

export default IndexPage
